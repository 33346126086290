import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const RequiredText = loadable(() =>
    import('/src/components/Form/Required/RequiredText')
)

const WizardStep = ({ title, requiredText, className, children }) => {
    const classes = ['c-wizard-step', ...(className ? [className] : [])]

    const concatenatedClasses = joinClasses(classes)

    return (
        <div className={concatenatedClasses}>
            {title && (
                <>
                    <h2 className="c-wizard-step__intro">{title}</h2>
                    {requiredText && <RequiredText />}
                </>
            )}

            <div className="c-wizard-step__inner">{children}</div>
        </div>
    )
}

WizardStep.propTypes = {
    /**
     * Optional title
     */
    title: PropTypes.string,
    /**
     * Whether required text is present
     */
    requiredText: PropTypes.bool,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

WizardStep.defaultProps = {
    requiredText: false,
    children: 'Place items here'
}

export default WizardStep
